import './App.css';
import React,{Suspense} from 'react';

// import Header from './Header/Header';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// import SimpleMagnifier from './SimpleMagnifier/SimpleMagnifier';

import NavBar from './NavBar/NavBar'
// import Introduction from './Introduction/Introduction'
// import Documentation from './Documentation/Documentation'
// import Demo01 from './Demo01/Demo01'
// import Demo02 from './Demo02/Demo02'
// import Demo03 from './Demo03/Demo03'
// import Musings from './Musings/Musings'


const Introduction = React.lazy(()=> import('./Introduction/Introduction'));
const Documentation = React.lazy(()=> import('./Documentation/Documentation'));
const Demo01 = React.lazy(()=> import('./Demo01/Demo01'));
const Demo02 = React.lazy(()=> import('./Demo02/Demo02'));
const Demo03 = React.lazy(()=> import('./Demo03/Demo03'));
const Musings = React.lazy(()=> import('./Musings/Musings'));

function App() {
  const basename = (window.location.host==="react347.gitlab.io" ? "/lfcmagnifier" : "");
  return (
<>
    <Router basename={basename}>

    <div className="App has-navbar-fixed-top">

      <Routes>
        <Route path="*" element={<NavBar/>}>
          <Route exact path="introduction" element={<Suspense><Introduction /></Suspense>} />
          <Route exact path="documentation" element={<Suspense><Documentation /></Suspense>} />
          <Route exact path="demo01" element={<Suspense><Demo01 /></Suspense>} />
          <Route exact path="demo02" element={<Suspense><Demo02 /></Suspense>} />
          <Route exact path="demo03" element={<Suspense><Demo03 /></Suspense>} />
          <Route exact path="musings" element={<Suspense><Musings /></Suspense>} />
          <Route path="*" element={<Navigate to="Introduction" replace />} />
        </Route>
        <Route path="*" element={<Navigate to="INtroduction" replace />} />
      </Routes>

    </div>
    </Router>
    </>  );
}

export default App;
