import imgNpm from '../img/npm.png';
import { useState } from 'react';

// import {FaFacebookF, FaTwitter, FaInstagram, FaDribbble } from 'react-icons/fa';
// import { CgCrown as BsTelephoneFill } from 'react-icons/cg'
import {SlMagnifier} from 'react-icons/sl';
import {BiLogIn, BiLogOut} from 'react-icons/bi';

//import { BsTelephoneFill } from 'react-icons/bs'

// import {GiPalmTree as MdEmail} from 'react-icons/gi'
//import { MdEmail } from 'react-icons/md'
// import imgLogo from '../img/gp-alpha.png'
import "./NavBar.css";
import { /*NavLink,*/ Outlet } from "react-router-dom";
import Tanuki from "../Tanuki/Tanuki"
import Login from "../Login/Login"
import { useAuth } from '../Login/hooks/useAuth.tsx';

export default function Header(){
    const [showModalLogin, setShowModalLogin] = useState();
    const auth = useAuth();
    return <div id="glam-header">

        <section className="">
            <div className="glam-header-container">
                <nav className="navbar is-black" role="navigation" aria-label="main navigation">
                    <div className="navbar-brand">
                        {/* <a className="navbar-item" href="brand"> */}
                        <div className="navbar-item">
                            <div className="media">
                                <div className="media-left">
                                    <figure className="image is-48x48" style={{fontSize:"40px"}}>
                                        <SlMagnifier/>
                                    </figure>
                                </div>
                                <div className="media-content " style={{overflow:'hidden'}}>
                                    <p className="subtitle is-4 has-text-left">Homepage</p>
                                    <p className="title is-6 has-text-left">@lylech/react-image-magnifier</p>
                                </div>
                            </div>
                        {/* </a> */}
                        </div>

                        <div className="navbar-item">

                        </div>

                        <a href="." role="button" aria-label="menu" aria-expanded="false" data-target="myBurgerDropdown"
                            id="navbar-burger"
                            className="navbar-burger"
                            onClick={(e) =>{
                                e.preventDefault()
                                window.document.getElementById("myBurgerDropdown").classList.toggle("is-active");
                                window.document.getElementById("navbar-burger").classList.toggle("is-active");
                            }}
                        >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        </a>
                    </div>

                    <div id="myBurgerDropdown" className="navbar-menu">
                        <div className="navbar-start">
                            <a className="navbar-item" href="introduction">
                                Introduction
                            </a>

                            <div className="navbar-item has-dropdown is-hoverable">
                                <a className="navbar-link" href="demo01">
                                Demos
                                </a>
                                <div className="navbar-dropdown">
                                    <a className="navbar-item" href="demo01">
                                        1. Basic Functionality
                                    </a>
                                    <a className="navbar-item" href="demo02">
                                        2. Thumbnails & Buttons
                                    </a>
                                    <a className="navbar-item" href="demo03">
                                        3. Custom Layout
                                    </a>
                                    {/* <hr className="navbar-divider" />
                                    <a className="navbar-item" href="report an issue">
                                        Report an issue
                                    </a> */}
                                </div>
                            </div>

                            <a className="navbar-item" href="documentation">
                                Documentation
                            </a>

                            {auth.isAuthenticated && <a className="navbar-item" href="musings">
                                Musings
                            </a>}


                        </div>

                        <div className="navbar-end">
                            <div className="navbar-item">
                                <div className="buttons">
                                    {/* <a className="button is-primary">
                                        <strong>Sign up</strong>
                                    </a>
                                    <a className="button is-light">
                                        Log in
                                    </a> */}
                                    <a
                                        className="button is-light"
                                        href={"https://gitlab.com/react347/lfcmagnifier"}
                                        target={"_blank"} rel={"noreferrer"}>
                                            <Tanuki/>
                                    </a>

                                    <a
                                        className="button is-light"
                                        href={"https://www.npmjs.com/package/@lylech/react-image-magnifier"}
                                        target={"_blank"} rel={"noreferrer"}>
                                        <img alt="NPM Package" src={imgNpm}/>
                                    </a>

                                    {!auth.isAuthenticated && <a
                                        className="button is-light"
                                        href={"login"}
                                        style={{minWidth: '8rem'}}
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            setShowModalLogin(true)
                                        }}>
                                        <BiLogIn/>&nbsp;Login
                                    </a>}

                                    {auth.isAuthenticated && <a
                                        className="button is-light"
                                        href={"logout"}
                                        style={{minWidth: '8rem'}}
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            auth.signOut();
                                        }}>
                                        Logout&nbsp;<BiLogOut/>
                                    </a>}

                                </div>
                            </div>
                        </div>
                    </div>

                </nav>
            </div>
            {showModalLogin && <Login fnCancel={ (e)=>{ setShowModalLogin(false)}}/>}
            <Outlet/>

        </section>



{/*
        <section className="hero is-link">
  <div className="hero-body">
    <p className="title">
      Link hero
    </p>
    <p className="subtitle">
      Link subtitle
    </p>
  </div>
</section>
 */}


    </div>
}
