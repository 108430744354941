import { useRef } from 'react';
import {FaEnvelope, FaLock} from 'react-icons/fa';
import {BiLogIn} from 'react-icons/bi';
import {GiCancel} from 'react-icons/gi';
import {TiUserAddOutline} from 'react-icons/ti';

import "./Login.css";
import { useAuth } from './hooks/useAuth.tsx';

export default function Login(props){
    const auth = useAuth();
    const emailRef = useRef();
    const passwordRef = useRef();

    return <>
    <section className="hero is-primary" id="login">
    <div className="hero-body">
        <div className="container">
            <div className="columns is-centered">
                <div className="column is-6-tablet is-5-desktop is-4-widescreen">
                    <div className="box">
                        <div className="field">
                        <label htmlFor="" className="label">Email</label>
                        <div className="control is-small has-icons-left">
                            <input type="email" placeholder="e.g. jane@gmail.com" className="input" required ref={emailRef}/>
                            <span className="icon is-left">
                                <FaEnvelope/>
                            </span>
                        </div>
                        </div>
                        <div className="field">
                        <label htmlFor="" className="label">Password</label>
                        <div className="control has-icons-left">
                            <input type="password" placeholder="*******" className="input" required ref={passwordRef}/>
                            <span className="icon is-small is-left">
                                <FaLock/>
                            </span>
                        </div>
                        </div>
                        <div className="field">
                        <label htmlFor="" className="checkbox">
                            <input type="checkbox"/>
                        &nbsp;Remember me
                        </label>
                        </div>
                        <div className="field buttons">
                        <button className="button is-success" onClick={(e)=>{
                            console.log("Login clicked! %o", auth);
                            auth.signIn(emailRef.current.value, passwordRef.current.value, (msg)=>{
                                console.log("@Login fnCallback: %o", msg);
                                props?.fnCancel()
                            });

                        }}>
                            <BiLogIn />&nbsp;Login
                        </button>
                        <button className="button is-success" onClick={(e)=>{
                            console.log("Signup clicked! %o", auth);
                            auth.signOut();
                            props?.fnCancel()
                        }}>
                            <TiUserAddOutline/>&nbsp;Sign up
                        </button>
                        <button className="button is-success" onClick={(e)=>{props?.fnCancel() }}>
                            <GiCancel/>&nbsp;Cancel
                        </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
</>
}